
import React from 'react'
import '../pages/Register.css'
import pic from "../assets/20220625_032422.png";

import { useState } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Loader from './Loader';

function Registrations() {

	const [contacts, setContacts] = useState({

	})
	const [loading, setLoading] = useState(false);


	const [formErrors, setFormErrors] = useState({});


	const handleInputChange = (e) => {
		const { name, value } = e.target
		setContacts({ ...contacts, [name]: value })
	}
	const validate = (values) => {

		var error = {}
		var regName = /^[a-zA-Z ]+$/;
		var regPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
		var regPin = /^\d{6}$/
		var regpass = /^\d{4}$/

		if (!values.Fullname) {
			error.Fullname = "Name can not be empty"
		}else if(values.Fullname.trim()==''){
			error.Fullname = "Name can not be empty"
		}else if(!regName.test(values.Fullname)){
			error.Fullname = "Enter valid name"
		}

		if (!values.schoolName) {
			error.schoolName = "School Name can not be empty"
		}
		

		if (!values.Phone) {
			error.Phone = "Phone Number can not be empty"
		}
		else if (!regPhone.test(values.Phone)) {
			error.Phone = "Please enter valid phone number"
		}


		if (!values.teachingLevel) {
			error.teachingLevel = "Please Choose an option"
		}

		if (!values.preference) {
			error.preference = "Please Choose an option"
		}



		return error
	}





	const handleSubmit = (e) => {
		e.preventDefault();
		const errors = validate(contacts);

		

		if (Object.keys(errors).length === 0) {
			setLoading(true)
			const oldData = JSON.parse(localStorage.getItem('contacts'))
		if (oldData?.Fullname==contacts.Fullname||oldData?.Phone==contacts.Phone) {
			setLoading(false)
			return	Swal.fire({
				title: 'Error!',
				text: 'Already submited',
				icon: 'error',
				background: '#464545',
				color: '#13a273',
			});
		}
			const formDatass = new FormData();
			formDatass.append('Full_Name', contacts.Fullname);
			formDatass.append('School_Name', contacts.schoolName);
			formDatass.append('Phone', contacts.Phone);
			formDatass.append('Teaching_Level', contacts.teachingLevel);
			formDatass.append('Preference', contacts.preference);

			axios
				.post('https://script.google.com/macros/s/AKfycbwleWFPF_NV4hqXH302PBDGfvF2qFHNsbAtTQQa4ERYitHfauc_2ubNQ7Vnk5yNS9n2/exec', formDatass)
				.then((response) => {
					console.log(response);
					setContacts({
						Fullname: '',
						schoolName: '',
						Phone: '',
						teachingLevel: '',
						preference: '',
					});
					setLoading(false)
					localStorage.setItem('contacts',JSON.stringify(contacts))
					Swal.fire({
						title: 'Thank You!',
						text: 'Registration completed successfully.',
						icon: 'success',
						background: '#464545',
						color: '#13a273',
						showCancelButton: true,
						confirmButtonClass: 'custom-confirm-button',
						cancelButtonClass: 'custom-cancel-button',
						confirmButtonText: 'OK',
						cancelButtonText: 'Cancel',
					});
				})
				.catch((error) => {
					console.error('Error submitting the form:', error);
					setLoading(false)
					Swal.fire({
						title: 'Error!',
						text: 'An error occurred while submitting the form. Please try again later.',
						icon: 'error',
						background: '#464545',
						color: '#13a273',
					});
				});
		} else {
			setFormErrors(errors);
			
		}
	};




	return (

		<section class="main">
			<ToastContainer />
			<center><span><img src={pic} alt="" height="180px" width="250" style={{ objectFit: "cover", height: '73px' }} /></span></center>

			<div className='form' >
				<div class="main-box">
					<div class="input-box">
						<span class="text">Full Name<span style={{ color: "red" }}>*</span></span>
						<input type="text" placeholder="Please enter your Full Name" name='Fullname' value={contacts.Fullname} onChange={handleInputChange} onClick={() => { setFormErrors({ ...formErrors, Fullname: " " }) }} />

						<span style={{ color: formErrors.Fullname ? "red" : "" }} >{formErrors.Fullname} </span>
					
					
					
					
					</div>
	

					



					<div class="input-box">
						<span class="text">Phone Number(WhatsApp)<span style={{ color: "red" }}>*</span></span>
						<input type="text" placeholder="Phone Number" maxLength={10} minLength={10} onInput={(e)=>{e.target.value = e.target.value.replace(/[^0-9.]/g, ''); e.target.value = e.target.value.replace(/(\..*)\./g, '$1')}} name='Phone' value={contacts.Phone} onClick={() => { setFormErrors({ ...formErrors, Phone: " " }) }} onChange={handleInputChange} />
						<span style={{ color: formErrors.Phone ? "red" : "" }}> {formErrors.Phone}</span>


					</div>




					<div class="input-box">
						<span class="text">School Name<span style={{ color: "red" }}>*</span></span>
						<input type="text" placeholder="School name" name='schoolName' value={contacts.schoolName} onClick={() => { setFormErrors({ ...formErrors, schoolName: " " }) }} onChange={handleInputChange} />
						<span style={{ color: formErrors.schoolName ? "red" : "" }}> {formErrors.schoolName}</span>
					</div>


					<div class="input-box">
						<span class="text">Teaching Level<span style={{ color: "red" }}>*</span></span>
						<select name="teachingLevel" id="sel" value={contacts.teachingLevel} onClick={() => { setFormErrors({ ...formErrors, teachingLevel: " " }) }} onChange={handleInputChange} >
							<option className='option' value="">Choose Level</option>
							<option className='option' value="primaryLevel">Primary Level</option>
							<option className='option' value="secondaryLevel ">Secondary Level </option>
							<option className='option' value="seniorSecondaryLevel">Senior Secondary Level</option>
							<option className='option' value="professors">Professors</option>
						

						</select>
						<span style={{ color: formErrors.teachingLevel ? "red" : "" }}> {formErrors.teachingLevel}</span>
					</div>

					<div class="input-box">
						<span class="text">Preference<span style={{ color: "red" }}>*</span></span>
						<select name="preference" id="sel" value={contacts.preference} onChange={handleInputChange} onClick={() => { setFormErrors({ ...formErrors, preference: " " }) }}>
							<option className='option' value="">Choose Preference</option>
							<option className='option' value="Online">Online</option>
							<option className='option' value="Offline">Offline</option>
						


						</select>
						<span style={{ color: formErrors.preference ? "red" : "" }}> {formErrors.preference}</span>
					</div>








				</div>
				<div class="btn">

					<button type="button"  onClick={handleSubmit} >{loading==true?<Loader/>:'Register'}</button>

				</div>
			</div>
		</section>
	)
}

export default Registrations
