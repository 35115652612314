import React from 'react'
import './loader.css'
export default function Loader() {
    return (
        <div className="align" style={{paddingLeft:"50%",marginBottom:"1%"}}>
            <div class="spinner" >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

    )
}
